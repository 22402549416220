var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","sm":"12","align":"center"}},[_c('div',{staticClass:"login-panel"},[_c('div',{staticClass:"body-container"},[_c('div',{staticClass:"drop-page-responsive"},[_c('div',[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("@/assets/error12.svg")},on:{"error":_vm.replaceByDefault}})]),_c('div',{staticClass:"text-left",staticStyle:{"color":"#000000","font-size":"20px","font-weight":"600","max-width":"360px","margin-left":"50px","padding-top":"18px"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]),(_vm.showIllus)?_c('div',{staticStyle:{"text-align":"center","margin-top":"5px","margin-bottom":"30px","max-width":"100%"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/stream_error.svg"),"alt":"stream not accessible"}})]):_vm._e(),_c('div',{style:({
            marginTop: '50px',
            color:`${_vm.$store.getters.callConfig.textColor || '#2F75C7'}`,
            textAlign:'center',
            fontSize:'16px'
          })},[_vm._v(" We are sorry for your inconvenience. ")]),(_vm.showReschedule)?_c('div',{staticStyle:{"text-align":"center","font-size":"16px","margin-top":"5px","margin-bottom":"30px"}},[_c('span',{staticStyle:{"font-weight":"600","font-size":"20px"}},[_vm._v("Don’t worry,")]),_vm._v(" you can choose below option to proceed. ")]):_vm._e(),(_vm.showRouteOptions)?_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"px-5 py-5",style:({
                    width: '175px',
                    backgroundColor: `${_vm.$store.getters.callConfig.buttonOutlineColor || '#1976d2'}!important`,
                    color:`${_vm.$store.getters.callConfig.buttonTextColor || '#fff'}!important`
                  }),on:{"click":function($event){return _vm.redirectTo(_vm.redirectOptions[0])}}},[_vm._v(_vm._s(_vm.redirectOptions[0]))]),_c('div',{staticStyle:{"max-width":"380px","margin-top":"10px","font-size":"14px"}},[_vm._v(" You will be taken back to the device checks, but don’t worry! We’ll connect you to the next available "+_vm._s(_vm.displayName)+" representative. ")])],1),(_vm.showReschedule)?_c('v-col',[_c('v-btn',{staticClass:"px-5 py-5",style:({
                    width: '175px',
                    backgroundColor: `${_vm.$store.getters.callConfig.buttonOutlineColor || '#1976d2'}!important`,
                    color:`${_vm.$store.getters.callConfig.buttonTextColor || '#fff'}!important`
                  }),on:{"click":function($event){return _vm.redirectTo(_vm.redirectOptions[1])}}},[_vm._v(_vm._s(_vm.redirectOptions[1]))]),_c('div',{staticStyle:{"max-width":"380px","margin-top":"10px","font-size":"14px"}},[_vm._v(" Choose for rescheduling your call for any other time of your availability. ")])],1):_vm._e()],1):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }