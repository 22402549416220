<template>
  <v-content
    class="main"
    :style="assignedSkills.length ? 'padding-top:10px !important' : ''"
  >
    <v-tabs
      v-if="enableTabs"
      v-model="activeTab"
      centered
      background-color="transparent"
      @change="tabChange()"
    >
      <v-tab v-for="(call, index) in callsBucket" :key="call.tab">
        {{ call.tabLabel }}
        <v-badge
          color="green"
          offset-y="3"
          style="
            marginbottom: 1.85em;
            marginleft: -0.563em;
            marginright: -1.25em;
          "
          inline
        >
          <template v-slot:badge>
            <v-tooltip top :disabled="callsCount(index) < 1000">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  callsCount(index) > 999 ? "999+" : callsCount(index)
                }}</span>
              </template>
              <span>{{ callsCount(index) }}</span>
            </v-tooltip>
          </template>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(call, index) in callsBucket" :key="call.tab">
        <v-container>
          <div class="text-right">
            <v-pagination
              v-model="currentPage"
              :length="numberOfPages(index)"
              :total-visible="10"
            ></v-pagination>
          </div>
          <div class="text-center" v-if="callsBucket[index].count == 0">
            <p class="headline">No Calls present!</p>
          </div>
          <v-row row wrap>
            <v-col
              sm="12"
              cols="12"
              md="6"
              xl="4"
              lg="4"
              class="pa-1"
              v-for="item in cardData"
              :key="item.id"
            >
              <v-card>
                <v-card-text>
                  <v-row row wrap>
                    <v-col sm="12" cols="12" md="3" xl="3" lg="3" class="pa-2">
                      <img :src="item.avatar" style="width: 100%" />
                    </v-col>
                    <v-col sm="12" cols="12" md="9" xl="9" lg="9" class="pa-2">
                      <p class="title">{{ item.name }}</p>
                      <p class>{{ item.email }}</p>
                      <div v-if="item.callType.callSuccess">
                        <v-chip class="ma-2" color="success" text-color="white">
                          <v-avatar left>
                            <v-icon>fas fa-check-circle</v-icon> </v-avatar
                          >Success
                        </v-chip>
                      </div>
                      <div v-else-if="item.callType.retry">
                        <div
                          class="retry-btn"
                          v-if="!clicked.includes(item.id)"
                        >
                          <v-card-text name="error-txt" class="error-text">
                            <v-icon class="error-icon">
                              notranslate fas fa-exclamation-circle
                              theme--light</v-icon
                            >
                            Failed to submit!
                          </v-card-text>
                          <v-btn
                            class="
                              v-btn v-btn--contained v-btn--rounded
                              theme--dark
                              v-size--default
                              primary
                            "
                            @click="retriggerCase(item)"
                            v-bind:key="item.id"
                          >
                            RETRY
                          </v-btn>
                        </div>
                        <div v-else>
                          <v-chip
                            class="ma-2"
                            color="orange"
                            text-color="white"
                          >
                            <v-avatar left>
                              <v-icon>fas fa-clock</v-icon> </v-avatar
                            >Processing
                          </v-chip>
                        </div>
                      </div>
                      <div v-if="item.callType.processing">
                        <v-chip class="ma-2" color="orange" text-color="white">
                          <v-avatar left>
                            <v-icon>fas fa-clock</v-icon> </v-avatar
                          >Processing
                        </v-chip>
                      </div>
                      <!--feeback submit-->
                      <div v-if="item.callType.feedback == true">
                        <v-btn
                          class="v-btn v-btn--contained primary"
                          @click="gotoFeedbackForm(item)"
                          style="
                            background-color: #0089cc;
                            color: #ffffff;
                            font-size: 12px;
                            text-transform: none;
                          "
                        >
                          <v-icon left class="error-icon">
                            notranslate fas fa-exclamation-circle
                            theme--light</v-icon
                          >
                          Complete feedback
                        </v-btn>
                      </div>
                      <!--feeback submit-->
                      <div v-if="item.callType.recordingFailed">
                        <v-chip class="ma-2" color="red" text-color="white">
                          <v-avatar left>
                            <v-icon
                              >fas fa-exclamation-circle</v-icon
                            > </v-avatar
                          >Recording Failed
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <!--admin feedback form-->
    <v-dialog
      v-model="adminFormDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="signzy_color" style="overflow-y: auto">
        <v-toolbar dark color="signzy_color">
          <v-spacer />
            <v-toolbar-title>Submit Feedback</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="8">
              <v-card class="elevation-12">
                <v-card-text>
                  <div class="text-center form-header mt-4 mb-4">
                    <p class="headline">
                      {{
                        adminFormHeaderText ||
                        "CLASSIFY THE USER APPLICATION BASED ON THE BELOW CRITERIA"
                      }}
                    </p>
                    <center>
                      <v-divider style="width: 80%; margin-bottom: 20px">
                      </v-divider>
                    </center>
                  </div>
                  <div class="text-center">
                    <p class="subheading">
                      {{
                        adminFormSubHeaderText ||
                        "Kindly select the application status and leave your comments based on the interaction with user"
                      }}
                    </p>
                  </div>
                  <v-row justify="center">
                    <v-col cols="8">
                      <div v-for="i in forms" :key="i.variable">
                        <v-container
                          fluid
                          v-if="
                            i.elementType == 'multiSelectDropdown' &&
                            conditionalRender[i.variable]
                          "
                        >
                          <v-select
                            v-if="typeof i.options == 'string'"
                            v-model="dynamicFormVariables[i.variable]"
                            :items="dropDownOptions[i.options]"
                            :id="i.options"
                            item-text="text"
                            item-value="value"
                            attach
                            chips
                            @focus="setTypeId"
                            :label="i.placeHolder"
                            @change="checkFormDisabled()"
                            multiple
                            light
                          >
                            <template v-slot:prepend-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-text-field
                                    v-model="searchTerm"
                                    placeholder="Search"
                                    @input="search"
                                  ></v-text-field>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-select>
                          <v-select
                            v-else
                            v-model="dynamicFormVariables[i.variable]"
                            :items="i.options"
                            item-text="text"
                            item-value="value"
                            attach
                            chips
                            :label="i.placeHolder"
                            @change="checkFormDisabled()"
                            multiple
                          >
                          </v-select>
                        </v-container>
                        <v-container
                          fluid
                          v-else-if="
                            i.elementType == 'dropdown' &&
                            conditionalRender[i.variable]
                          "
                        >
                          <v-select
                            v-if="typeof i.options == 'string'"
                            v-model="dynamicFormVariables[i.variable]"
                            :items="dropDownOptions[i.options]"
                            item-text="text"
                            item-value="value"
                            :id="i.options"
                            @focus="setTypeId"
                            attach
                            @change="checkFormDisabled()"
                            :label="i.placeHolder"
                            light
                          >
                            <template v-slot:prepend-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-text-field
                                    v-model="searchTerm"
                                    placeholder="Search"
                                    @input="search"
                                  ></v-text-field>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-select>
                          <v-select
                            v-else
                            v-model="dynamicFormVariables[i.variable]"
                            :items="i.options"
                            item-text="text"
                            item-value="value"
                            attach
                            @change="checkFormDisabled()"
                            :label="i.placeHolder"
                          >
                          </v-select>
                        </v-container>
                        <v-text-field
                          v-else-if="
                            i.elementType == 'inputField' &&
                            conditionalRender[i.variable]
                          "
                          v-model="dynamicFormVariables[i.variable]"
                          @change="checkFormDisabled()"
                          :label="i.placeHolder"
                        >
                        </v-text-field>
                        <v-text-field
                          v-else-if="
                            i.elementType == 'number' &&
                            conditionalRender[i.variable]
                          "
                          v-model="dynamicFormVariables[i.variable]"
                          @change="checkFormDisabled(true, i.variable)"
                          :label="i.placeHolder"
                        >
                        </v-text-field>
                        <v-textarea
                          solo
                          v-else-if="
                            i.elementType == 'textarea' &&
                            conditionalRender[i.variable]
                          "
                          :label="i.placeHolder"
                          @change="checkFormDisabled()"
                          v-model="dynamicFormVariables[i.variable]"
                        >
                        </v-textarea>
                      </div>
                      <div class="text-center">
                        <v-btn
                          :disabled="isDisabled"
                          color="success"
                          @click="submitFeedbackForCall()"
                          >Submit Feedback</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions></v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--admin feedback form-->
  </v-content>
</template>

<script>
import { end_points, vcip_end_points, base_url } from "@/config";
import signzyAvatar from "@/Plugins/SignzyAvatarGenerator.js";
import { getSession } from "@/Plugins/videoProcess";
import { searchMatchedDropdownValues } from "@/Plugins/vcipCallService";
import axios from "axios";

const axiosInstance = window.axiosInstance;

export default {
  data: () => ({
    backopsId: "",
    currentPage: 1,
    limit: 9,
    restrictQueryData: false,
    restrictedNumberOfDays: 0,
    clicked: [],
    blockedTime: 5,
    //feedback form models
    adminFormDialog: false,
    forms: [],
    dynamicFormVariables: {},
    conditionalRender: {},
    isLegacySetting: false,
    isMultiFlow:false,
    dropDownOptions: {},
    dummydropDownOptions:{},
    isSearchTriggered:false,
    typeId: null,
    searchTerm: "",
    isDisabled: true,
    selectedSession: {},
    isVideoConference: false,
    agentData: null,
    assignedSkills: [],
    currentSkillTag: [],
    adminFormHeaderText: "",
    adminFormSubHeaderText: "",
    activeTab: 0,
    lookupSessionId: {},
    updatedCategorizedCallsResponse: {},
    updateCategorizedCalled: 0,
    callUpdateTimeout:null,
    processingSessionIds:[],
    callsBucket: [
      //Do not alter the positioning of the objects inside this array
      {
        tab: "allCalls",
        tabLabel: "All Calls",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "success",
        tabLabel: "Success",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "processing",
        tabLabel: "Processing",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "completeFeedback",
        tabLabel: "Complete Feedback",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "retry",
        tabLabel: "Retry",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "recordingFailed",
        tabLabel: "Recording Failed",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
      {
        tab: "uncategorized",
        tabLabel: "Uncategorized",
        count: 0,
        hover: false,
        sessionIds: [],
        paginatedPages: [],
      },
    ],
    cardData: [],
    callTypes: {
      success: {
        retry: false,
        callSuccess: true,
      },
      processing: {
        processing: true,
      },
      completeFeedback: {
        feedback: true,
      },
      retry: {
        retry: true,
        triggerCallBack: true,
      },
      recordingFailed: {
        retry: false,
        recordingFailed: true,
      },
      uncategorized: {
        retry: false,
        callSuccess: false,
      },
    },
    enableTabs: false,
  }),
  computed: {
    callsCount: function () {
      return (pos) => this.callsBucket[pos].count + "";
    },
    numberOfPages: function () {
      return (pos) => this.callsBucket[pos].paginatedPages.length;
    },
  },
  watch: {
    currentPage: function () {
      this.generateRenderDetails();
    },
  },
  methods: {
    getInitialAvatar(name) {
      return signzyAvatar({ name });
    },
    async retriggerCase(item) {
      this.clicked.push(item.id);
      let options = {
        sessionId: item.sessionId,
        email: item.email,
        abruptCancel: false,
        isRetryCase: true,
      };
      console.log("inside retry case::", item);
      if (item.callType.triggerCallBack) {
        console.log("Callback + Status Update API calling");
        options.triggerCallBack = true;
        axios.post(vcip_end_points.end_session, options);
        eventBus.$emit("vueSnack", "Retry in Progress");
      } else {
        //TODO -->  call for status update Api
        console.log("Only Status Update API calling");
        options.triggerCallBack = false;
        axios.post(vcip_end_points.end_session, options);
        eventBus.$emit("vueSnack", "Retry in Progress");
      }
      this.processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];
      this.processingSessionIds.push(item.sessionId)
      sessionStorage.setItem('processingSessionIds', JSON.stringify(this.processingSessionIds));
      this.checkAndUpdateCategorizedCalls();
      // this.$router.go(this.$router.currentRoute);
    },
    tabChange() {
      this.currentPage = 1;
      this.generateRenderDetails();
    },
    paginate(arr) {
      return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / this.limit);
        let page = acc[idx] || (acc[idx] = []);
        page.push(val);
        return acc;
      }, []);
    },
    generateDate(days) {
      let generatedDate = new Date();
      generatedDate.setDate(generatedDate.getDate() - days);
      return generatedDate
        .toISOString()
        ?.substring(0, 10)
        ?.replaceAll("-", "/");
    },
    generateRenderDetails() {
      this.cardData.length = 0;
      this.callsBucket[this.activeTab].paginatedPages[
        this.currentPage - 1
      ]?.forEach((sessionId) => {
        let lookupData = this.lookupSessionId[sessionId];
        if (this.callsBucket[this.activeTab].tab == "allCalls") {
          for (let i = 1; i < this.callsBucket.length; i++) {
            if (this.callsBucket[i].sessionIds.includes(sessionId)) {
              this.cardData.push({
                sessionId,
                name: lookupData.name,
                email: lookupData.email,
                avatar: this.getInitialAvatar(lookupData.name),
                callType: this.callTypes[this.callsBucket[i].tab],
              });
            }
          }
        } else {
          this.cardData.push({
            sessionId,
            name: lookupData.name,
            email: lookupData.email,
            avatar: this.getInitialAvatar(lookupData.name),
            callType: this.callTypes[this.callsBucket[this.activeTab].tab],
          });
        }
      });
    },
    injectDataIntoBucket(data) {
      this.callsBucket.forEach((field, idx) => {
        this.callsBucket[idx].count = data[field.tab]?.count;
        this.callsBucket[idx].sessionIds = data[field.tab]?.sessionIds;
        this.callsBucket[idx].paginatedPages = this.paginate(
          data[field.tab]?.sessionIds
        );
      });
      this.lookupSessionId = data.sessionIdToUserDataMap;
    },
    async getCategorizedCalls() {
      this.enableTabs = false;
      eventBus.$emit("persistedLoader", true);
      try {
        let categorizedCallsResponse = await axiosInstance.get(
          end_points.vcip_get_categorized_calls(this.backopsId),
          {
            ...(this.restrictQueryData && this.restrictedNumberOfDays > 0
              ? {
                  params: {
                    onOrAfterThisDate: this.generateDate(
                      this.restrictedNumberOfDays
                    ),
                  },
                }
              : {}),
          }
        );

        this.injectDataIntoBucket(categorizedCallsResponse.data);
        this.generateRenderDetails();
        eventBus.$emit('getNextEndUser');
        this.enableTabs = true;

        this.processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];
        // Remove session IDs if callsBucket does not has that session ID 
        for(let i=0;i<this.processingSessionIds.length;i++)
        {
          if(!(this.callsBucket[2].sessionIds.includes(this.processingSessionIds[i])||this.callsBucket[6].sessionIds.includes(this.processingSessionIds[i])))
          {
            this.processingSessionIds.splice(i, 1);
            i--;
          }
        }
      sessionStorage.setItem('processingSessionIds', JSON.stringify(this.processingSessionIds));
  
      } catch (error) {
        eventBus.$emit(
          "vueSnack",
          "Something went wrong while fetching categorized calls."
        );
        console.log(
          "Something went wrong while fetching categorized call data: ",
          error
        );
      }
      eventBus.$emit("persistedLoader", false);
    },
    checkAndUpdateCategorizedCalls() {

      this.callUpdateTimeout = setTimeout(() => {
        this.processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];
        if (this.processingSessionIds.length != 0 && this.updateCategorizedCalled < 20) {
          this.updateCategorizedCalled += 1;
          this.updateCategorizedCalls();
        }
        else{
          sessionStorage.setItem('processingSessionIds', JSON.stringify([]));
        }
      }, 7000);
    },
    UpdateDataOfBucket(data) {
      this.processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];
      const resultMap = new Map();
      for (const key of Object.keys(data)) {
        if (key === 'allCalls') {
          continue;
        }
        const sessionIds = data[key]?.sessionIds;
        if (Array.isArray(sessionIds)) {
          for (const sessionId of sessionIds) {
            resultMap.set(sessionId, key);
          }
        }
      }
      console.log('resultMap', resultMap);
      let isUpdated=false;

      for (let i = 0; i < this.callsBucket.length; i++) {
        const bucket = this.callsBucket[i];
        const tab = bucket.tab;
        if (tab == 'allCalls') continue;
        // Add session IDs to bucket from data if they are not already present
        // Updating each calldata bucket count from updateCalls reponse
        for (let j = 0; j < data[tab].sessionIds.length; j++) {
          const sessionId = data[tab].sessionIds[j];
          if (!bucket.sessionIds.includes(sessionId)) {
            bucket.sessionIds.push(sessionId);
            bucket.count=bucket.sessionIds.length;
          }
        }

        // Remove session IDs if resultMap has that session ID and its value is not equal to tab
        for (let j = 0; j < bucket.sessionIds.length; j++) {
          const sessionId = bucket.sessionIds[j];
          if (resultMap.get(sessionId)  && resultMap.get(sessionId) !== tab) {
            bucket.sessionIds.splice(j, 1);
            bucket.count=bucket.sessionIds.length;
            j--;
            isUpdated=true;
          }
          //remove from processingSessionIds
          const index = this.processingSessionIds?.indexOf(sessionId);
          if (index !== -1 && tab != "processing" && tab != "uncategorized") {
            this.processingSessionIds.splice(index, 1);
          }
        }
      }
      sessionStorage.setItem('processingSessionIds', JSON.stringify(this.processingSessionIds));

      if (isUpdated) {
        try {
            this.generateRenderDetails();
        } catch (error) {
          console.log(error);
        }
      }
      this.checkAndUpdateCategorizedCalls();
    },

    async updateCategorizedCalls() {
      try {
         this.updatedCategorizedCallsResponse = await axiosInstance.get(
          end_points.vcip_get_categorized_calls(this.backopsId),
          {
            ...({
                params: {
                  limit:9,
                  currentPage:this.currentPage,
                },
              }),
          }
        );
        this.UpdateDataOfBucket(this.updatedCategorizedCallsResponse.data);
      } catch (error) {
        console.log(error);
        eventBus.$emit(
          "vueSnack",
          "Something went wrong while fetching categorized calls."
        );
        console.log(
          "Something went wrong while fetching categorized call data: ",
          error
        );
      }
    },
    async search(val) {
      try {
        this.isSearchTriggered=true;
        this.dropDownOptions={...this.dummydropDownOptions};
        let dropDownValues = await searchMatchedDropdownValues(
          this.typeId,
          this.searchTerm,
          val
        );
        const values = this.dropDownOptions[this.typeId];
        const filteredOptions = values.filter(option => dropDownValues.includes(option.text));
        this.dropDownOptions = {
            ...this.dropDownOptions,
            [this.typeId]: filteredOptions
        };
      } catch (error) {
        console.log("Something went wrong while searching ::::", error);
      }
    },
     setTypeId(reference) {
      this.typeId = reference.target.id;
      this.searchTerm = "";
       if (this.isSearchTriggered) {
         this.dropDownOptions = { ...this.dummydropDownOptions };
         this.isSearchTriggered = false;
       }
      
    },
    checkFormDisabled(check, val) {
      // check is true if value is to be treated as number only
      if (check) {
        let result;
        if (
          this.dynamicFormVariables[val][
            this.dynamicFormVariables[val].length - 1
          ] === "."
        ) {
          result = this.dynamicFormVariables[val].replace(/[^0-9.]/g, "");
        } else {
          result = parseFloat(
            this.dynamicFormVariables[val].replace(/[^0-9.]/g, "")
          );
        }
        if (result.toString() !== "NaN") {
          const res = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.dynamicFormVariables[val] = res;
        } else {
          this.dynamicFormVariables[val] = "";
        }
      }
      let selfIns = this;
      for (let i = 0; i < selfIns.forms.length; i++) {
        let item = selfIns.forms[i];
        if (item.isRequired) {
          if (selfIns.dynamicFormVariables[item.variable]) {
            selfIns.isDisabled = false;
          } else {
            selfIns.isDisabled = true;
            break;
          }
        } else {
          selfIns.isDisabled = false;
        }

        //Check for the conditional Rendering
        if (item.conditionalRendering) {
          for (let j = 0; j < selfIns.forms.length; j++) {
            if (
              selfIns.forms[j].variable ===
              item.conditionalRenderingConditions.variable
            ) {
              if (
                (selfIns.forms[j].elementType === "dropdown" ||
                  selfIns.forms[j].elementType === "multiSelectDropdown") &&
                selfIns.forms[j].optionalValuesRequired
              ) {
                let checker;
                if(this.isMultiFlow){
                for (let k = 0; k < this.forms[j].options.length; k++) {
                  if (this.forms[j].options[k].text === item.conditionalRenderingConditions.data) {
                    checker = this.forms[j].options[k].value;
                    break;
                  }
                }
                }else{
                for (let k = 0; k < this.dropDownOptions[this.forms[j].optionsValue].length; k++) {
                  if (this.dropDownOptions[this.forms[j].optionsValue][k].text === item.conditionalRenderingConditions.data) {
                    checker = this.dropDownOptions[this.forms[j].optionsValue][k].value;
                    break;
                  }
                }}
                if (
                  selfIns.dynamicFormVariables[
                    item.conditionalRenderingConditions.variable
                  ] == checker
                ) {
                  selfIns.$set(selfIns.conditionalRender, item.variable, true);
                } else {
                  selfIns.$set(selfIns.conditionalRender, item.variable, false);
                  delete selfIns.dynamicFormVariables[item.variable];
                }
              }else if(selfIns.forms[j].elementType === "number") {
                let numericValue=parseFloat(selfIns.dynamicFormVariables[
                      item.conditionalRenderingConditions.variable
                    ]?.replace(/,/g, ''));
                if (
                    numericValue== item.conditionalRenderingConditions.data
                  ) {
                    selfIns.$set(selfIns.conditionalRender, item.variable, true);
                  } else {
                    selfIns.$set(selfIns.conditionalRender, item.variable, false);
                    delete selfIns.dynamicFormVariables[item.variable];
                  }
                }  else {
                if (
                  selfIns.dynamicFormVariables[
                    item.conditionalRenderingConditions.variable
                  ] == item.conditionalRenderingConditions.data
                ) {
                  selfIns.$set(selfIns.conditionalRender, item.variable, true);
                } else {
                  selfIns.$set(selfIns.conditionalRender, item.variable, false);
                  delete selfIns.dynamicFormVariables[item.variable];
                }
              }
              break;
            }
          }
        }
      }
    },
    async submitFeedbackForCall() {
      try {
        this.isDisabled = true;
        let sessionId = this.selectedSession.sessionId;
        let response = await axiosInstance.post(
          end_points.updateCallSessionData(sessionId),
          { fieldValues: this.dynamicFormVariables }
        );
        if (response && response.status == 200) {
          let feedbackStatus = await axiosInstance.post(
            end_points.sessionFeedbackStatus(sessionId),
            { feedbackFormSubmit: true }
          );
          if (feedbackStatus && feedbackStatus.status == 200) {
            let endSessionResponse = await axiosInstance.post(
              vcip_end_points.end_session,
              {
                sessionId: this.selectedSession.sessionId,
                email: this.selectedSession.users[0].email ?? '',
                phone: this.selectedSession.users[0].phone ?? '',
                abruptCancel: this.selectedSession.users[0]?.abruptCancel || false,
                feedback:"dashboard",
                afterCallFeedback: this.dynamicFormVariables,
                callId: this.selectedSession?.callbackSystem?.eventCallbackParameters?.callId
              }
            );
            if (endSessionResponse && endSessionResponse.status == 200) {
              eventBus.$emit("vueSnack", "Feedback Successfully Submited.");
              let updateCallResponse = await axiosInstance.post(
                end_points.update_call_status,
                {
                  sessionId: this.selectedSession.sessionId,
                  type: "kyc",
                  isCancelled: false,
                }
              );
              if (updateCallResponse && updateCallResponse.status == 200) {
                console.log("sucessfully ended session");
                this.adminFormDialog = false;
                this.getCategorizedCalls();
                this.processingSessionIds = JSON.parse(sessionStorage.getItem('processingSessionIds')) || [];                   
                this.processingSessionIds.push(this.selectedSession.sessionId);
                sessionStorage.setItem('processingSessionIds', JSON.stringify(this.processingSessionIds));
                this.checkAndUpdateCategorizedCalls();
              } else {
                console.log("update Call response failed");
                eventBus.$emit("vueSnack", "Feedback submit failed !");
              }
            } else {
              console.log("end session response failed");
              eventBus.$emit("vueSnack", "Feedback submit failed !");
              this.adminFormDialog = false;
            }
          } else {
            console.log("end session response failed");
            eventBus.$emit("vueSnack", "Feedback submit failed !");
            this.adminFormDialog = false;
            this.isDisabled = false;
          }
        } else {
          eventBus.$emit("vueSnack", "Feedback submit failed !");
          this.adminFormDialog = false;
          this.isDisabled = false;
        }
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Feedback submit failed !");
        this.adminFormDialog = false;
        this.isDisabled = false;
      }
    },
    checkStringValInObject(object){
      for(let key in object){
        if(typeof(object[key]) == 'string'){
          return object;
        }else{
          checkStringValInObject(object[key])
        }
      }
    },
    async gotoFeedbackForm(callItem) {
      //get sessionObj for selected call
      let sessionObj;
      sessionObj = await getSession(callItem.sessionId);

      this.selectedSession = sessionObj;

      //admin form labels as per flow
      this.adminFormHeaderText =
        sessionObj.configuration &&
        sessionObj.configuration.adminFeedbackFormHeaderText
          ? sessionObj.configuration.adminFeedbackFormHeaderText
          : "CLASSIFY THE USER APPLICATION BASED ON THE BELOW CRITERIA";
      this.adminFormSubHeaderText =
        sessionObj.configuration &&
        sessionObj.configuration.adminFeedbackFormSubHeaderText
          ? sessionObj.configuration.adminFeedbackFormSubHeaderText
          : "Kindly select the application status and leave your comments based on the interaction with user";

      //create admin form
      let AdminfinalArray = [];
      let fields = sessionObj.fields;
      if (fields && fields.length != 0) {
        let jsonData = fields;
        jsonData.forEach((item) => {
          this.dynamicFormVariables[item.variable] = "";
          this.$set(
            this.conditionalRender,
            item.variable,
            !item.conditionalRendering
          );
        });

        this.forms = jsonData;
        for (let i = 0; i < this.forms.length; i++) {
          this.isLegacySetting = false;
          if (
            this.forms[i].elementType === "dropdown" ||
            this.forms[i].elementType === "multiSelectDropdown"
          ) {
            if (typeof this.forms[i].options !== "object") {
              let dummyArr = [];
              if (typeof this.forms[i].options === "string") {
                dummyArr.push(this.forms[i].options);
                this.forms[i].options = dummyArr;
              }

              try {
                const options = await axiosInstance.get(
                  base_url +
                    end_points.dropDownFields +
                    this.forms[i].options[0]
                );
                this.forms[i].options = options.data.data;
              } catch (error) {
                console.log("Error occured while fetching dropdown ::", error);
                eventBus.$emit(
                  "vueSnack",
                  "Something went wrong, ask user to rejoin !"
                );
              }
            } else {
              this.isLegacySetting = true;
              this.isMultiFlow=true;
            }

            this.forms[i].options.forEach((option) => {
              if (typeof option == "object") {
                let valuedObject = this.checkStringValInObject(option);
                let newObj = {};
                if (valuedObject.text) {
                  newObj["text"] = valuedObject.text;
                } else {
                  newObj["text"] = valuedObject.value;
                }
                if (
                  !this.forms[i].optionalValuesRequired ||
                  (this.forms[i].optionalValuesRequired &&
                    !this.forms[i].optionalValues)
                ) {
                  newObj["value"] = valuedObject.value;
                } else {
                  if (
                    Object.keys(this.forms[i].optionalValues).includes(
                      valuedObject["text"]
                    ) &&
                    this.forms[i].optionalValues[valuedObject["value"]].trim()
                      .length > 0
                  ) {
                    newObj["value"] =
                      this.forms[i].optionalValues[valuedObject["value"]];
                  } else {
                    newObj["value"] = valuedObject["value"];
                  }
                }

                AdminfinalArray.push(newObj);
              } else {
                let newObj = { text: option };
                if (
                  !this.forms[i].optionalValuesRequired ||
                  (this.forms[i].optionalValuesRequired &&
                    !this.forms[i].optionalValues)
                ) {
                  newObj["value"] = option;
                } else {
                  if (
                    Object.keys(this.forms[i].optionalValues).includes(
                      option
                    ) &&
                    this.forms[i].optionalValues[option].trim().length > 0
                  ) {
                    newObj["value"] = this.forms[i].optionalValues[option];
                  } else {
                    newObj["value"] = option;
                  }
                }
                AdminfinalArray.push(newObj);
              }
            });

            if (this.isLegacySetting) {
              this.forms[i].options = AdminfinalArray;
              AdminfinalArray = [];
            } else {
              this.dropDownOptions[this.forms[i].optionsValue] =
                AdminfinalArray;
              this.forms[i].options = this.forms[i].optionsValue;
              AdminfinalArray = [];
            }
          }
        }
      }
      //call feedback form modal
      this.dummydropDownOptions={...this.dropDownOptions}
      this.adminFormDialog = true;
    },
  },
  beforeDestroy() {
    eventBus.$off("updateDashboardCalls");
  },
  async created() {
    this.backopsId = this.$store.getters.userData.userId;
    this.agentData = (
      await axiosInstance.get(end_points.get_agent_data(this.backopsId))
    ).data;
    this.assignedSkills = this.agentData.assignedSkills
      ? this.agentData.assignedSkills
      : [];

    if (
      this.$store.getters.userData?.customerData?.config?.global?.hasOwnProperty(
        "restrictQueryData"
      )
    ) {
      if (
        this.$store.getters.userData.customerData.config.global
          .restrictQueryData
      ) {
        this.restrictQueryData = true;
        this.restrictedNumberOfDays = +(
          this.$store.getters.userData.customerData.config.global
            ?.restrictedNumberOfDays ?? 0
        );
      }
    }

    await this.getCategorizedCalls();


    eventBus.$on("updateDashboardCalls", () => {
      this.getCategorizedCalls();
      this.checkAndUpdateCategorizedCalls();
    });
    window.onbeforeunload = () => {
        clearTimeout(this.callUpdateTimeout);
        sessionStorage.setItem('processingSessionIds', JSON.stringify([]));
        return null;
      };
    this.checkAndUpdateCategorizedCalls();
  },
};
</script>

<style scoped>
.v-pagination {
  width: auto !important;
}
@media (min-width: 1440px) {
  .container {
    margin: auto;
    max-width: 1070px !important;
  }
}
.text-right {
  padding-bottom: 5%;
}
.error-icon {
  color: #fe0a13;
}
.v-card__text.error-text {
  color: #fe0a13;
  padding-left: 0%;
  text-align: left;
}
.retry-btn {
  text-align: right;
  padding-right: 5%;
}
.main {
  flex: 1 1 auto;
  max-width: 82%;
  position: relative;
}

</style>
