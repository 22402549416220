<template>
  <v-content>
    <v-container fluid>
      <p class="headline">Call History</p>
      <div class="text-right">
        <v-pagination v-model="currentPage" :length="length" :total-visible="10"></v-pagination>
      </div>
      <v-row align="center">
        <v-col cols="12" sm="12" md="6" xl="4" lg="4" pa-2 v-for="(item, i) in allCalls" :key="i">
          <v-card class="mt-2 mb-2" :class="item.isCompleted ? 'completed' : 'expired'">
            <v-card-text>
              <div class="float-right">
                <v-btn
                  v-if="!item.isCancelled"
                  small
                  color="primary"
                  @click="showDetails(item)"
                >View more</v-btn>
                <v-btn v-if="item.isCancelled" small depressed color="error">Cancelled Call</v-btn>
              </div>
              <span class="subtitle-2">{{item.confSession.users[0].name}}</span>
              <br />
              <span>{{item.confSession.users[0].email}}</span>
              <br />
              <span
                class="subtitle-2"
              >Scheduled at {{new Date(item.from - (offset * 3600000)).toLocaleString()}}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center" v-if="allCalls.length==0">
        <p class="headline">No Calls are present!</p>
      </div>
      <v-dialog v-model="showResult" persistent max-width="700">
        <v-card>
          <div class="d-flex align-center pt-2 pl-2">
            <v-btn icon color="black" class="mr-3" @click="goBack()">
              <v-icon>fas fa-arrow-left</v-icon>
            </v-btn>
            <div class="headline">Call details</div>
          </div>
          <v-card-text class="mt-4">
            <span class="subtitle-1">Call Scheduled On:</span>
            <div
              class="float-right"
              v-if="!isCompleted && (meetingData.from - (offset * 3600000)) > Date.now()"
            >
              <v-btn
                small
                color="primary"
                @click="$router.push({name:'adminCall',params:{session: meetingData.confSession.sessionId}})"
              >Join Now</v-btn>
            </div>
            <p>{{new Date(meetingData.from - (offset * 3600000)).toLocaleString()}}</p>
            <v-divider class="mb-2"></v-divider>
            <span class="subtitle-1">Invited Users:</span>
            <br />
            <div v-for="(item,i) in meetingData.confSession.users" :key="i">
              <span class="subtitle-2">{{item.name}}</span>
              <br />
              <span class="subtitle-2">{{item.email}}</span>
              <br />
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <span class="title">Meeting details</span>
            <v-text-field
              id="meetingUrl"
              v-model="meetingData.confSession.userConfUrl"
              label="Meeting Link"
              readonly
            ></v-text-field>
            <div v-if="!isCompleted && (meetingData.from - (offset * 3600000)) > Date.now()">
              <v-row>
                <v-col cols="6" pa-1>
                  <v-btn block small color="primary" @click="sendEmail()">Send Email</v-btn>
                </v-col>
                <v-col cols="6" pa-1>
                  <v-btn block small color="primary" @click="copyIt()">Copy URL</v-btn>
                </v-col>
              </v-row>
              <v-btn block small color="error" @click="cancelCall()">Cancel Call</v-btn>
            </div>
            <div v-else-if="isCompleted">
              <video
                v-if="videoUrl && videoData && !videoDownloadStarted"
                controls
                preload="none"
                width="100%"
                :poster="meetingData.videoThumbnail && videoThumbnailData"
                :src="videoData"
              >
                <source type="video/mp4" />
              </video>
              <div class="text-center" v-if="!videoUrl">
                <p>No Recording data found!</p>
              </div>
              <div class="text-center" v-if="videoUrl && videoDownloadStarted">
                <div class="bar">
                  <i class="sphere"></i>
                </div>
              </div>
              <div v-if="meetingData.rating">
                <v-divider class="mt-2 mb-2"></v-divider>
                <span class="title">Feedback details</span>
                <br />
                <span class="subtitle-2">{{meetingData.rating}} Stars</span>
                <span
                  v-if="meetingData.feedback.length!=0"
                  class="subtitle-2"
                >, Feedback: {{meetingData.feedback}}</span>
              </div>
            </div>
            <div
              v-else-if="!isCompleted && (meetingData.from - (offset * 3600000)) <= Date.now()"
              class="text-center"
            >
              <p v-if="meetingData.config && meetingData.config.isRecording" class="headline">Meeting Expired!</p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
  </v-content>
</template>

<script>
let offset = (new Date().getTimezoneOffset() / 60) * -1;
import { end_points, vcip_end_points, constants } from "@/config";
import { downloadFile } from "@/flux/persist.flux";
import { getSession } from "@/Plugins/videoProcess";
import jwt from "jsonwebtoken";
export default {
  data: () => ({
    allCalls: [],
    currentPage: 1,
    limit: 9,
    totalCalls: 0,
    showResult: false,
    isCompleted: false,
    videoUrl: "",
    videoDownloadStarted: false,
    videoThumbnailData: null,
    videoData: null,
    offset: (new Date().getTimezoneOffset() / 60) * -1,
    meetingData: {
      confSession: {
        users: []
      },
      config: {
        isRecording: false
      }
    }
  }),
  computed: {
    length: function() {
      return Math.ceil(this.totalCalls / this.limit);
    }
  },
  methods: {
    goBack() {
      this.showResult = false;
      this.videoUrl = "";
      this.meetingData = {
        confSession: {
          users: []
        },
        config: {
          isRecording: false
        }
      };
    },
    async sendEmail() {
      try {

        // expires in 2 mins
        let token = jwt.sign({data:this.meetingData.confSession.sessionId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });

        await axiosInstance.post(vcip_end_points.send_email, {
          email: this.meetingData.callTo.email,
          name: this.meetingData.callTo.name,
          meetingCode: this.meetingData.confSession.meetingCode,
          url: this.meetingData.confSession.userConfUrl,
          token
        });
        eventBus.$emit("vueSnack", "Successfully sent email!");
      } catch (error) {
        eventBus.$emit("vueSnack", "Email sending failed!");
      }
    },
    async cancelCall() {
      if (confirm("Are you sure, you want to cancel the call ?")) {
        try {
          await axiosInstance.post(end_points.cancel_call, {
            callId: this.meetingData.id,
            isCancelled: true
          });
          this.getAllCalls();
          eventBus.$emit("vueSnack", "Successfully cancelled the call!");
        } catch (error) {
          console.log(error);
          eventBus.$emit("vueSnack", "Call cancellation failed!");
        }
      }
    },
    copyIt() {
      /* Get the text field */
      var copyText = document.getElementById("meetingUrl");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      eventBus.$emit("vueSnack", "Successfully Copied!");
    },
    async showDetails(item) {
      this.showResult = true;
      this.isCompleted = false;
      this.videoUrl = "";
      this.meetingData = item;
      if (!this.meetingData.config || !this.meetingData.config.isRecording) {
        this.meetingData.config = {
          isRecording: false
        };
      }
      try {
        if (
          this.meetingData &&
          this.meetingData.output &&
          this.meetingData.output.confDataCb &&
          this.meetingData.output.confDataCb.data &&
          this.meetingData.output.confDataCb.data.recordedVideoData &&
          this.meetingData.output.confDataCb.data.recordedVideoData.videoUrl
        ) {
          this.videoUrl = this.meetingData.output.confDataCb.data.recordedVideoData.videoUrl;
          this.videoDownloadStarted = true;
          let respVideo = await downloadFile(this.videoUrl);
          if (respVideo.status === 200) {
            this.videoData = `data:${respVideo.headers["content-type"].split(";")[0]};base64, ${respVideo.data.file}`;
            this.videoDownloadStarted = false;
          }
          this.isCompleted = true;
          this.meetingData.rating = this.meetingData.output.confDataCb.data.feedback;
          this.meetingData.feedback = this.meetingData.output.confDataCb.data.feedbackMessage;
          if (this.meetingData.output.confDataCb.data.recordedVideoData.videoThumbnail) {
            this.meetingData.videoThumbnail =  this.meetingData.output.confDataCb.data.recordedVideoData.videoThumbnail;
            let respImg = await downloadFile(this.meetingData.videoThumbnail);
            if (respImg.status === 200) {
              this.videoThumbnailData =  `data:${respImg.headers["content-type"].split(";")[0]};base64, ${respImg.data.file}`;
            }
          }
        } else {
          this.videoUrl = "";
          this.isCompleted = false;
        }
      } catch (error) {
        console.log(error);
        this.videoUrl = "";
        this.isCompleted = false;
      }
      //Check for config obj
      if (
        this.meetingData &&
        this.meetingData.config &&
        this.meetingData.config.isRecording == false
      ) {
        console.log("yessss!!");
        this.isCompleted = true;
      }
    },
    clearMeetingData() {
      this.meetingData = {
        confSession: {
          users: []
        },
        config: {
          isRecording: false
        }
      };
    },
    async getAllCalls() {
      // getCalls gets todays calls by default, if start and end query param epoch is not passed
      let calls = (
        await axiosInstance.get(
          end_points.get_all_calls_paginated(
            this.currentUserData.userId,
            this.limit,
            this.currentPage
          )
        )
      ).data;
      this.allCalls = [];
      calls.forEach(element => {
        if (
          element &&
          element.output &&
          element.output.confDataCb &&
          element.output.confDataCb.data &&
          element.output.confDataCb.data.recordedVideoData &&
          element.output.confDataCb.data.recordedVideoData.videoUrl
        ) {
          element.isCompleted = true;
        } else {
          element.isCompleted = false;
        }

        //Check for config obj if is recording is false, then give green signal!
        if (element && element.config && element.config.isRecording == false) {
          element.isCompleted = true;
        }
        this.allCalls.push(element);
      });
    }
  },
  watch: {
    currentPage: function() {
      this.getAllCalls();
    }
  },
  async created() {
    try {
      if (this.$store.getters.userData) {
        let { userId, token } = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = token;
        let resp = (await axiosInstance.get(end_points.get_agent_data(userId)))
          .data;
        this.currentUserData = {
          ...this.$store.getters.userData,
          ...resp
        };
        this.$store.commit("setUserData", this.currentUserData);
        let callCountResponse = await axiosInstance.get(
          end_points.call_count(this.currentUserData.userId)
        );
        this.totalCalls = callCountResponse.data.count;
        this.getAllCalls();
      } else {
        this.$router.push("/");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/");
    }
    // this.agents = [
    //   {
    //     name: "James bond",
    //     email: "bond@james.com"
    //   },
    //   {
    //     name: "Spider Man",
    //     email: "makadi.manav@marvel.com"
    //   },
    //   {
    //     name: "Jack Sparrow",
    //     email: "dani.daniels@blackperl.com"
    //   },
    //   {
    //     name: "Shaktiman",
    //     email: "shakt.shati@doordarsha.com"
    //   },
    //   {
    //     name: "Jaado",
    //     email: "dhoop@koimilgaya.com"
    //   }
    // ];
  }
};
</script>

<style scoped>
.completed {
  box-shadow: 0px 0px 3px 2px #4caf50;
}
.expired {
  box-shadow: 0px 0px 3px 2px #e6584e;
}
.v-pagination {
  width: auto !important;
}.bar {
	/* Size and position */
    font-size: 20px; /* 1em */
    width: 10em;
    height: 1em;
    position: relative;
    margin: 100px auto;

    /* Styles */
    border-radius: .5em; /* Height/2 */
    background: white; /* Fallback */
    background: rgba(255,255,255,0.6);
    box-shadow:
        0 0 0 .05em rgba(100,100,100,0.075), /* Subtle border */
        0 0 0 .25em rgba(0,0,0,0.1),		   /* Outter border */
        inset 0 .1em .05em rgba(0,0,0,0.1),   /* Inset shadow */
        0 .05em rgba(255,255,255,0.7);	   /* Slight reflection */
}
.bar:after {
	/* Content and position */
    content: "Please wait while the video is being loaded.";
    position: absolute;
    left: 5%;
    top: 150%;

    /* Font styles */
    font-family: 'Carrois Gothic', sans-serif;
    font-size: 14px;
    color: #555;
    text-shadow: 0 .05em rgba(255,255,255,0.7);
}
.sphere {
    /* Size */
    display: block;
    width: 1em;
    height: 100%;

    /* Styles */
    border-radius: 50%;
    background: linear-gradient(#eee, #ddd);
    box-shadow:
        inset 0 .15em .1em rgba(255,255,255,0.3), /* Top light */
        inset 0 -.1em .15em rgba(0,0,0,0.15),	   /* Bottom shadow */
        0 0 .25em rgba(0,0,0,0.3);			   /* Outter shadow */

    /* Animation */
    animation: move 1.75s ease-in-out infinite alternate;
}
@keyframes move {
    to { margin-left: 90%; }
}

</style>
