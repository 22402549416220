<template>
  <v-row>
    <v-col lg="12" md="12" sm="12" align="center">
      <div class="login-panel">
        <!-- <img class="signzy-logo" src="@/assets/signzy-logo-dark.png" /> -->
        <div class="login-form">

            <!-- browser version incompatible -->
            <div v-if="desktopBrversionIn || iosBrVersionIn || mobBrVersionIn || macBrVersionIn">
                <p class="error-message" style="color:#2F75C7;font-weight:bold;">Sorry, Your browser version is Old and Incompatible</p>
                <br>
                <v-divider style="width:50%"></v-divider>
                <br><br>
                <p>Our application is best suited in the following browser version</p>
                <br>
                    <div v-if="iosBrVersionIn">
                      <img src="@/assets/safari.svg" style="cursor:pointer" @click="redirectToUpdate('safari')" />
                      <p style="font-size:14px">Safari( {{ IOSVersionComp }} )</p>
                    </div>
                    <div v-if="macBrVersionIn" style="display:flex;justify-content:center;align-items:center;">

                      <div style="margin:0% 2% 0% 2%; cursor:pointer"><img src="@/assets/safari.svg" @click="redirectToUpdate('safari')"/>
                      <p style="font-size:14px">Safari( {{ IOSVersionComp }} )</p>  </div>
                       
                      <div style="margin:0% 2% 0% 2%; cursor:pointer"><img src="@/assets/chrome.svg" @click="redirectToUpdate('chrome')"/>
                      <p style="font-size:14px">Chrome( {{ chromeVersionComp }} )</p>  </div>
                       
                      <div  style="margin:0% 2% 0% 2%; cursor:pointer"><img src="@/assets/firefox.svg"  @click="redirectToUpdate('firefox')" />
                      <p style="font-size:14px">Firefox( {{ firefoxVersionComp }} )</p>  </div>


                    </div>
                    <div v-if="mobBrVersionIn">
                      <img src="@/assets/chrome.svg" style="cursor:pointer" @click="redirectToUpdate('chrome')" />
                      <p style="font-size:14px">Chrome( {{ chromeVersionComp }})</p>
                    </div>
                    <div v-if="desktopBrversionIn" style="display:flex;justify-content:center;align-items:center;" >
                     
                             <div style="margin:0% 2% 0% 2%; cursor:pointer"><img src="@/assets/chrome.svg" @click="redirectToUpdate('chrome')"/>
                             <p style="font-size:14px">Chrome( {{ chromeVersionComp }} )</p>  </div>
                       
                           <div  style="margin:0% 2% 0% 2%; cursor:pointer"><img src="@/assets/firefox.svg"  @click="redirectToUpdate('firefox')" />
                             <p style="font-size:14px">Firefox( {{ firefoxVersionComp }} )</p>  </div>

                           <div  style="margin:-5px 2% 0% 2%; cursor:pointer"><img src="@/assets/edge.svg"  @click="redirectToUpdate('edge')" />
                             <p style="font-size:14px; margin-top:-5px">Edge( {{ edgeVersionComp }} )</p>  </div> 
                          
                            
                    </div>
                <br>
                <p style="color: #056DAD"> Please update the browser to perform Video KYC, Click on the browser icon to download the compatible version.</p>
                <br>
                <v-divider style="width:50%"></v-divider>
                <br>

            </div>

              <!-- browser Incompatible -->
            <div v-if="desktopBrIn || iosBrIn || mobBrIn || macBrIn">
                <p class="error-message" style="color:#2F75C7;font-weight:bold;">Sorry, Your browser is Incompatible</p>
                <br>
                <v-divider style="width:50%"></v-divider>
                <br><br>
                <p>Our application is best suited in the following browser(s)</p>
                <br>
                    <div v-if="iosBrIn">
                      <img src="@/assets/safari.svg" />
                      <p style="font-size:14px">Safari</p>
                    </div>
                     <div v-if="macBrIn" style="display:flex;justify-content:center;align-items:center;">
                            <div style="margin:0% 2% 0% 2%"><img src="@/assets/safari.svg" />
                            <p style="font-size:14px">Safari</p>  </div>
                        
                            <div  style="margin:0% 2% 0% 2%"><img src="@/assets/chrome.svg" />
                            <p style="font-size:14px">Chrome</p>  </div>    

                            <div  style="margin:0% 2% 0% 2%"><img src="@/assets/firefox.svg" />
                            <p style="font-size:14px">Firefox</p>  </div>    
                            
                    </div>
                    <div v-if="mobBrIn">
                      <img src="@/assets/chrome.svg"/>
                      <p style="font-size:14px">Chrome</p>
                    </div>
                    <div v-if="desktopBrIn" style="display:flex;justify-content:center;align-items:center;" >
                     
                             <div style="margin:0% 2% 0% 2%"><img src="@/assets/chrome.svg" />
                             <p style="font-size:14px">Chrome</p>  </div>
                       
                           <div  style="margin:0% 2% 0% 2%"><img src="@/assets/firefox.svg" />
                             <p style="font-size:14px">Firefox</p>  </div>

                           <div  style="margin:-5px 2% 0% 2%"><img src="@/assets/edge.svg" />
                             <p style="font-size:14px; margin-top:-5px">Edge</p>  </div>                            
                    </div>
               
                <p style="color: #056DAD">Please open the link from any of these browser(s) to perform Video KYC.</p>
                <br>
                <v-divider style="width:50%"></v-divider>
                <br>

            </div>

          <p class="error-message" :style="[deviceNotCompatible && {'color' : '#2F75C7'}]">{{errorMessage}}</p>
          <p v-if="deviceNotCompatible" class="" style="font-size:16px; width: 80%; margin: 0 auto mb-2" >Please open the link from another device to perform Video KYC.</p>
          <p v-if="deviceNotCompatible" class="" style="font-size:16px; width: 90%; margin: 0 auto mb-2">Our solution works best on desktop/laptop and the following mobile versions:</p>
          <p v-if="deviceNotCompatible" class="" style="font-size:16px; width: 90%; margin: 0 auto; color: #2F75C7">Chrome in Android 8.1+, Safari in iOS 11+</p>
        </div>
      </div>
      
    </v-col>
  </v-row>
</template>

<script>
import { end_points } from "@/config";
import {logReportData}  from  "@/Plugins/reportLogger.js";
import { errorProcessor } from "@/flux/errorProcessor.flux.js";

export default {
  data: () => ({
    username: "",
    password: "",
    errorMessage: "",
    errorKey: "unexpectedError",
    chromeVersionComp:"v80+",
    IOSVersionComp:"v11+",
    firefoxVersionComp:"v78+",
    edgeVersionComp: "v90+",
    deviceNotCompatible: false,
    desktopBrIn: false,
    desktopBrversionIn: false,
    iosBrIn: false,
    iosBrVersionIn:false,
    mobBrIn:false,
    mobBrVersionIn:false,
    macBrIn:false,
    macBrVersionIn:false
  }),
  methods: {
    isLoadedInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    redirectToUpdate(browser){
      if(browser==="chrome"){
        window.location.replace("https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en");
      }
      else if(browser==="firefox"){
        window.location.replace("https://support.mozilla.org/en-US/kb/update-firefox-latest-release");
      }
      else if(browser==="safari"){
        window.location.replace("https://support.apple.com/en-in/HT204416");
      }
      else if(browser==="edge"){
        window.location.replace("https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1");
      }
    },
    retry() {
    if (sessionStorage.getItem("callAgainHelper")) {
        this.$router.replace({
          path: `${sessionStorage.getItem("callAgainHelper")}`
        });
    } else {
        eventBus.$emit("vueSnack", "Something went wrong!");
    }
  }
  },
  async created() {
    if (this.$route.params.reason) {
      this.errorKey = this.$route.params.reason;
      switch (this.$route.params.reason) {
        case "expired":
          this.errorMessage = "It seems that your session is expired!";
          break;
        case "nosupport":
          this.errorMessage =
            "Sorry this feature is not currently supported in your device!";
          break;
        case "noGrant":
          this.errorMessage =
            "It seems that your dont have access to the system, Please contact admin!";
          break;
        case "abrupt":
          this.errorMessage =
            "It seems that your call was not completed successfully! ";
          break;
        case "pageNotFound":
          this.errorMessage =
            "It seems that the webpage you are trying to access does not exist!";
          break;
        case "notCompatible":
            this.errorMessage = 
              "Sorry, your device is not compatible with Video KYC";
            this.deviceNotCompatible = true;
        break;
        case "streamNotVisible":
          this.errorMessage =
            "There was some error in accessing your camera feed / microphone. Please try again with a different device.";
          break;
        case "heartbeatFailed":
          this.errorMessage = "Your call has been dropped because your device was unable to respond to server's ping! Please try again in sometime";
        break; 
        case "browserIncompatible":
          this.desktopBrIn= true;
        break;
        case "browserVersionIncompatible":
          this.desktopBrversionIn = true;
        break;
        case "IOSBrowserIncompatible":
          this.iosBrIn = true;
        break;
        case "IOSBrowserVersionIncompatible":
          this.iosBrVersionIn = true;
        break;
        case "mobileBrowserIncompatible":
          this.mobBrIn = true;
        break;
        case "mobileBrowserVersionIncompatible":
          this.mobBrVersionIn = true;
        break;
        case "macBrowserIncompatible":
          this.macBrIn = true;
        break;
        case "macBrowserVersionIncompatible":
          this.macBrVersionIn = true;
        break;
        case "multipleSession":
          this.errorMessage = "Multiple sessions detected! There's already an active session.";
        break;
        case "documentExpired":
          this.errorMessage = "It seems that your document is expired!";
        break;
        default:
          this.errorKey = "unexpectedError";
          this.errorMessage = "There was a glitch";
        break;
      }
    } else {
      this.errorKey = "unexpectedError";
    }

    if (this.isLoadedInIframe()) {
      await errorProcessor(this.errorKey).then((resp) => {
        console.log("Sending error post message!!!!", resp, ",", this.errorKey);
        window.parent.postMessage(
          { 
            message: "videoUploadingFailed",
            error: {
              errorCode: resp,
              errorKey: this.errorKey, 
              errorMessage: this.errorMessage, 
            }
          }, "*");
        
        if(sessionStorage.getItem("requestId")){
          axiosInstance.post(end_points.trigger_call_back(sessionStorage.getItem("requestId")), {
            eventFor: "videoUploadingFailed",
            error: {
              errorCode: resp,
              errorKey: this.errorKey, 
              errorMessage: this.errorMessage, 
            }
          });
        }
      }).catch((err) => {
        console.log("Some error happened", err);
        window.parent.postMessage(
          { 
            message: "videoUploadingFailed",
            error: {
              errorCode: "VKYC_E_049",
              errorKey: this.errorKey, 
              errorMessage: this.errorMessage, 
            }
          }, "*");

        if(sessionStorage.getItem("requestId")){
          axiosInstance.post(end_points.trigger_call_back(sessionStorage.getItem("requestId")), {
            eventFor: "videoUploadingFailed",
            error: {
              errorCode: "VKYC_E_049",
              errorKey: this.errorKey, 
              errorMessage: this.errorMessage, 
            }
          });
        }
      })
    }
  }

  
};

</script>
<style scoped>
.login-bg {
  background-color: #1891cf;
  background-size: cover;
  /* background-image: url("~@/assets/vcip_it_admin.svg"), */
  /* linear-gradient(#313545, #242835); */
}
.lower-description {
  position: absolute;
  margin-left: 20px;
  z-index: 1;
  color: white;
}
.login-panel {
  top: 15vh;
  position: relative;
}
.login-form {
  position: relative;
  width: 80%;
}
.signzy-logo {
  height: 80px;
}
.error-message {
  font-size:32px; 
  width: 50%; 
  margin: 0 auto;
  margin-bottom: 15px;
}
.error-message-subtitle {
  font-size:20px; 
  width: 50%; 
  margin: 0 auto;
  margin-bottom: 15px;
}
@media screen and (max-width: 700px) {
  .error-message {
    width: 90%; 
    margin-bottom: 15px;
  }
}
</style>