var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"align-content-end flex-wrap login-bg hidden-sm-and-down d-none d-md-flex d-lg-flex d-xl-flex",attrs:{"lg":"8","md":"8","sm":"0"}},[_c('div',{staticClass:"lower-description"},[_c('p',{staticClass:"display-2"},[_vm._v("Welcome to Video Conference")]),_c('p',{staticClass:"headline"},[_vm._v("Update your password to proceed further")])])]),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"12","align":"center"}},[_c('div',{staticClass:"login-panel"},[_c('div',{staticClass:"login-form"},[_c('label',{attrs:{"for":"password1"}},[_vm._v(" Enter new Password "),_c('Password',{attrs:{"label":"Enter new Password","name":"password1","toggle":true,"badge":false,"showStrengthMeter":false,"outlined":""},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}}),_c('div',{staticClass:"password-meter"},[_c('div',{staticClass:"password-strength-bar"},[_c('span',{class:{
                    'grey' : _vm.passwordStrength === 0,
                    'darkred' : _vm.passwordStrength === 1,
                    'red' : _vm.passwordStrength === 2,
                    'orange' : _vm.passwordStrength === 3,
                    'yellow' : _vm.passwordStrength === 4,
                    'green' : _vm.passwordStrength === 5
                }}),_c('span',{class:{
                    'grey' : _vm.passwordStrength <=1,
                    'red' : _vm.passwordStrength === 2,
                    'orange' : _vm.passwordStrength === 3,
                    'yellow' : _vm.passwordStrength === 4,
                    'green' : _vm.passwordStrength === 5
                }}),_c('span',{class:{
                    'grey' : _vm.passwordStrength <=2,
                    'orange' : _vm.passwordStrength === 3,
                    'yellow' : _vm.passwordStrength === 4,
                    'green' : _vm.passwordStrength === 5
                }}),_c('span',{class:{
                    'grey' : _vm.passwordStrength <=3,
                    'yellow' : _vm.passwordStrength === 4,
                    'green' : _vm.passwordStrength === 5
                }}),_c('span',{class:{
                    'grey' : _vm.passwordStrength <=4,
                    'green' : _vm.passwordStrength === 5
                }})])])],1),(_vm.isLengthInvalid)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Minimum of 8 characters required")]):_vm._e(),(_vm.isLowerCaseMissing)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Lowercase characters are required")]):_vm._e(),(_vm.isUpperCaseMissing)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Uppercase characters are required")]):_vm._e(),(_vm.isDigitMissing)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Digits are required")]):_vm._e(),(_vm.isSymbolMissing)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Symbols _$#@^!*+)({}'\\?/<>.,^[] are required")]):_vm._e(),_c('v-btn',{attrs:{"rounded":"","color":"primary","block":"","disabled":_vm.isLengthInvalid ||
            _vm.isLowerCaseMissing ||
            _vm.isUpperCaseMissing ||
            _vm.isDigitMissing ||
            _vm.isSymbolMissing},on:{"click":_vm.updatePass}},[_vm._v("Proceed")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }