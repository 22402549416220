<template>
  <v-content>
    <v-container>
      <v-row align="center" justify="center">
        <v-col md="8" cols="12" sm="12" lg="8" pa-2>
          <v-card>
            <v-card-text>
              <span class="title">Create a new call</span>
              <v-divider></v-divider>
              <p class="mt-3 mb-4 subtitle-2">Please enter the details of the participant:</p>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  label="Email Id of participant"
                  :rules="rules.email"
                  v-model="email"
                  outlined
                ></v-text-field>
                <v-text-field
                  label="Name of participant"
                  :rules="rules.name"
                  v-model="name"
                  outlined
                ></v-text-field>
                <v-text-field
                  label="Name of meeting"
                  :rules="rules.name"
                  v-model="meetingName"
                  outlined
                ></v-text-field>
                <!-- <v-checkbox v-model="schedule" label="Is this a scheduled call?"></v-checkbox> -->
                <v-row v-if="schedule">
                  <v-col md="6" sm="12" cols="12" lg="6">
                    <v-dialog
                      ref="dateMenu"
                      v-model="dateDialog"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date"
                          label="Picker in dialog"
                          prepend-icon="mdi-date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker :min="getToday()" v-model="date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateDialog = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dateMenu.save(date)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" lg="6">
                    <v-dialog
                      ref="timeMenu"
                      v-model="timeDialog"
                      :return-value.sync="time"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="time"
                          label="Picker in dialog"
                          prepend-icon="mdi-access_time"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-if="timeDialog" v-model="time" full-width>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="timeDialog = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="checkAndSaveTime()">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn :disabled="!valid" color="primary" @click="createCall();">Create call</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <div class="mt-5 mb-3" v-if="upcomingCalls.length!=0">
            <span class="title">Upcoming calls for today</span>
            <v-divider class="mb-4"></v-divider>
            <div v-for="(item, i) in upcomingCalls" :key="i">
              <v-card class="mt-2 mb-2">
                <v-card-text>
                  <span class="subtitle-2">{{item.confSession.users[0].name}}</span>
                  <br />
                  <span>{{item.confSession.users[0].email}}</span>
                  <br />
                  <span
                    class="subtitle-2"
                  >Scheduled at {{new Date(item.from - (offset * 3600000)).toLocaleString()}}</span>
                  <div class="float-right">
                    <v-btn small color="primary" @click="showDetails(item, true)">Join Now</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="showResult" persistent max-width="500">
        <v-card>
          <div class="d-flex align-center pt-2 pl-2">
            <v-btn v-if="isOld" icon color="black" class="mr-3" @click="showResult=false;">
              <v-icon>fas fa-arrow-left</v-icon>
            </v-btn>
            <v-btn v-if="!isOld" icon color="black" class="mr-3">
              <v-icon>fas fa-info-circle</v-icon>
            </v-btn>
            <div class="headline">Call details</div>
          </div>
          <v-card-text class="mt-4">
            <span class="subtitle-1">Call Scheduled On:</span>
            <div class="float-right">
              <v-btn
                small
                color="primary"
                @click="letsGo()"
              >Join Now</v-btn>
            </div>
            <p>{{new Date(meetingData.from - (offset * 3600000)).toLocaleString()}}</p>
            <v-divider class="mb-2"></v-divider>
            <span class="subtitle-1">Invited Users:</span>
            <br />
            <div v-for="(item,i) in meetingData.confSession.users" :key="i">
              <span class="subtitle-2">{{item.name}}</span>
              <br />
              <span class="subtitle-2">{{item.email}}</span>
              <br />
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <span class="title">Meeting details</span>
            <v-text-field
              id="meetingUrl"
              v-model="meetingData.confSession.userConfUrl"
              label="Meeting Link"
              readonly
            ></v-text-field>
            <v-row>
              <v-col cols="6" pa-1>
                <v-btn block small color="primary" @click="sendEmail()">Send Email</v-btn>
              </v-col>
              <v-col cols="6" pa-1>
                <v-btn block small color="primary" @click="copyIt()">Copy URL</v-btn>
              </v-col>
            </v-row>
            <v-btn block small color="error" @click="cancelCall()">Cancel Call</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
let offset = (new Date().getTimezoneOffset() / 60) * -1;
import { end_points, vcip_end_points, constants } from "@/config";
import jwt from "jsonwebtoken";
import enLib from "crypto-js/aes";
export default {
  props: ['checkInterval'],
  data: () => ({
    currentUserData: {},
    meetingData: {
      confSession: {
        users: [],
      },
    },
    offset: (new Date().getTimezoneOffset() / 60) * -1,
    rules: {
      mandatory: [(v) => /^.+$/.test(v) || "Field cannot be empty!"],
      name: [
        (v) => !!v || "Field cannot be empty!",
        (v) =>
          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(v) ||
          "Not a valid name!",
      ],
      email: [
        (v) => !!v || "Field cannot be empty!",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Not a proper email!",
      ],
    },
    valid: false,
    meetingName: "",
    upcomingCalls: [],
    schedule: false,
    name: "",
    customerId: "",
    email: "",
    dateDialog: false,
    showResult: false,
    timeDialog: false,
    time: "",
    isOld: false,
    date: new Date(new Date().getTime() + offset * 3600000)
      .toISOString()
      .substr(0, 10),
    dateMenu: {},
    timeMenu: {},
    customerConfig: {},
  }),
  watch: {
    date(val) {
      console.log("DATE: ", typeof val, val);
    },
    time(val) {
      console.log("TIME: ", typeof val, val);
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.name = "";
      this.email = "";
      this.meetingName = "";
      this.schedule = false;
      this.time = this.getTodayTime();
      this.date = new Date(new Date().getTime() + offset * 3600000)
        .toISOString()
        .substr(0, 10);
    },
    letsGo() {
      clearInterval(this.checkInterval)
      this.$store.commit('setInitiated', true);
      this.$router.push({
        name:'adminCall',
        params:{sessionId: this.meetingData.confSession.sessionId, requestId: this.meetingData.id }
      });
    },
    async cancelCall() {
      if (confirm("Are you sure, you want to cancel the call ?")) {
        try {
          await axiosInstance.post(end_points.cancel_call, {
            callId: this.meetingData.id,
            isCancelled: true,
          });
          this.showResult = false;
          this.reset();
          this.getupcomingCalls();
          eventBus.$emit("vueSnack", "Successfully cancelled the call!");
        } catch (error) {
          console.log(error);
          eventBus.$emit("vueSnack", "Call cancellation failed!");
        }
      }
    },
    async sendRescheduleEmail(name, email, date, time, token) {
      let dataToPost = {
        email: email,
        name: name,
        rescheduleDate: date,
        rescheduleTime: time,
        isConference: true,
        token
      };
      dataToPost = JSON.stringify(dataToPost);
      dataToPost = enLib.encrypt(dataToPost, this.$store.getters.envValues.otpEncKey).toString();

      await axiosInstance.post(vcip_end_points.send_email_reschedule, {dataToPost});
    },
    async sendEmail() {
      try {

        // expires in 2 mins
        let token = jwt.sign({data:this.meetingData.confSession.sessionId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });

        await axiosInstance.post(vcip_end_points.send_email, {
          email: this.meetingData.callTo.email,
          name: this.meetingData.callTo.name,
          meetingCode: this.meetingData.confSession.meetingCode,
          url: this.meetingData.confSession.userConfUrl,
          token
        });
        eventBus.$emit("vueSnack", "Successfully sent email!");
      } catch (error) {
        eventBus.$emit("vueSnack", "Email sending failed.");
      }
    },
    checkAndSaveTime() {
      let dts = `${this.date}T${this.time}`;
      let timeDiff = Date.parse(dts) - Date.now();
      if (timeDiff <= 0) {
        eventBus.$emit(
          "vueSnack",
          "Schedule calls cannot have a time in past!"
        );
      } else if (timeDiff <= 300000) {
        eventBus.$emit(
          "vueSnack",
          "Schedule call cannot be set within 5 minutes!"
        );
      } else {
        this.$refs.timeMenu.save(this.time);
      }
    },
    copyIt() {
      /* Get the text field */
      var copyText = document.getElementById("meetingUrl");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      eventBus.$emit("vueSnack", "Successfully Copied!");
    },
    clearMeetingData() {
      this.meetingData = {
        confSession: {
          users: [],
        },
      };
    },
    showDetails(item, isOld) {
      this.showResult = true;
      this.meetingData = item;
      this.isOld = isOld;
    },
    getTodayTime() {
      let time = new Date();
      let hours = time.getHours(),
        minutes = time.getMinutes();

      if (hours.toString().length < 2) hours = `0${hours}`;
      if (minutes.toString().length < 2) minutes = `0${minutes}`;

      return [hours, minutes].join(":");
    },
    getToday() {
      let d = new Date();
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async createCall() {
      try {
        if (this.name && this.email && this.meetingName) {
          let createdCall = await axiosInstance.post(
            end_points.agent_create_call(this.currentUserData.userId),
            {
              meetingName: this.meetingName,
              callTo: {
                name: this.name,
                email: this.email,
              },
              callFrom: {
                name:
                  this.currentUserData.name || this.currentUserData.username,
                email: this.currentUserData.email,
              },
              scheduled: this.schedule ? true : false,
              from: this.schedule
                ? new Date(`${this.date}T${this.time}`).getTime() +
                  offset * 3600000
                : Date.now() + offset * 3600000,
              customerId: this.currentUserData.customerData.id,
              configuration: this.currentUserData.customerData.config.introConf,
              fields: this.currentUserData.customerData.config.fields
            }
          );
          if (createdCall.data) {
            this.meetingData = createdCall.data;
            this.showResult = true;
            if (this.schedule) {
              this.isOld = true;

              // expires in 2 mins
              let token = jwt.sign({data:createdCall.data.confSession.sessionId}, this.$store.getters.envValues.otpEncKey, { expiresIn: 60 * 2 });
              this.sendRescheduleEmail(
                this.name,
                this.email,
                this.date,
                this.time,
                token
              );
              this.getupcomingCalls();
            } else {
              this.isOld = false;
              eventBus.$emit(
                "vueSnack",
                "An email has already been sent to the participant!"
              );
            }
            this.reset();
          } else {
            eventBus.$emit("vueSnack", "Something went wrong!");
          }
        } else {
          eventBus.$emit("vueSnack", "Required fields are empty!");
        }
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Call creation failed!");
      }
    },
    startMeeting(meetingCode) {
      console.log("meetingCode", meetingCode);
    },
    validate() {},
    async getupcomingCalls() {
      // getCalls gets todays calls by default, if start and end query param epoch is not passed
      let calls = (
        await axiosInstance.get(
          end_points.get_all_calls(this.currentUserData.userId)
        )
      ).data;
      this.upcomingCalls = [];
      calls.forEach((item) => {
        ///
        if (
          item.from - offset * 3600000 > new Date().getTime() &&
          !item.isCancelled
        ) {
          this.upcomingCalls.push(item);
        }
      });
      console.log({ upcomingCalls: this.upcomingCalls });
    },
    async getAllCalls() {
      // getCalls gets todays calls by default, if start and end query param epoch is not passed
      this.allCalls = (
        await axiosInstance.get(
          end_points.get_all_calls(this.currentUserData.userId)
        )
      ).data;
    },
  },
  async created() {
    this.time = this.getTodayTime();
    try {
      if (this.$store.getters.userData) {
        let { userId, token, customerData } = this.$store.getters.userData;
        if (
          customerData &&
          customerData.config &&
          customerData.config.conference
        ) {
          this.customerConfig = customerData.config.conference;
        }
        axiosInstance.defaults.headers.common["Authorization"] = token;
        let resp = (await axiosInstance.get(end_points.get_agent_data(userId)))
          .data;
        this.currentUserData = {
          ...this.$store.getters.userData,
          ...resp,
        };
        this.getupcomingCalls();
        this.$store.commit("setUserData", this.currentUserData);
      } else {
        this.$router.push("/");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/");
    }
  },
};
</script>

<style>
</style>
