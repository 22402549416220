<template>
  <v-content class="landingBackground ma-0 pa-0" fluid>
    <v-toolbar v-if="!hideAppBar" flat color="signzy_header_color" id="appToolBar">
      <v-toolbar-title>
        <img style="margin-left:10px" :src="imageLogo" height="30px;" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row justify="center" class="ma-0 pa-0">
      <v-col cols="12" sm="12" md="10" lg="8" xl="6" v-if="isLoading">
        <div class="text-center">
          <p class="headline mt-3">Please wait..</p>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6" v-if="!isLoading" class="ma-0 pa-0">
        <v-card class="vcipCard elevation-0">
          <!-- <v-card-title v-if="$route.name!='startVideo'" class="vcipTitle">{{processLabel}}</v-card-title> -->
          <v-card-text>
            <router-view :sessionId="sessionId" :callData="callData"></router-view>
          </v-card-text>
          <v-card-actions v-if="!hideBranding && $route.name!='startVideo'" class="float-right">
            <img style="width:150px;" src="@/assets/signzy-logo-secured.png" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="noSupport" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-4">Sorry, currently we dont have support for your device!</p>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import { padDoubleDigits, getRandomInt } from "@/Plugins/utils.js";
import { end_points } from "./../../config";
import { verifySession } from "./../../Plugins/videoProcess";
import DetectRTC from "detectrtc";
import axios from "axios";
export default {
  data: () => ({
    requestId: "",
    sessionId: "",
    isLoading: true,
    noSupport: false,
    hideAppBar: false,
    checkbox: false,
    imageLogo: "",
    processLabel: "Video KYC",
    hideBranding: false,
    callData: {
      configuration: {},
    },
  }),
  methods: {
    getRequestIdAndToken(tknString) {
      try {
        const { requestId, token } = JSON.parse(window.atob(tknString));
        return {
          requestId,
          token,
        };
      } catch (error) {
        console.log(error);
        return {
          requestId: tknString,
          token: undefined,
        };
      }
    },
  },
  async created() {
    let selfIns = this;
    this.sessionId = this.$route.params.sessionId;
    this.meetingCode = this.$route.query.meetingCode;
    this.email = this.$route.query.email;
    if (this.sessionId && this.meetingCode && this.email) {
      let verifyData = await verifySession(
        this,
        this.email,
        this.meetingCode,
        this.sessionId
      );
      this.$store.commit("setEndUserData", ["sessionId", this.sessionId]);
      this.$store.commit("setEndUserData", ["email", this.email]);
      this.$store.commit("setEndUserData", ["meetingCode", this.meetingCode]);
      this.isLoading = false;
      this.callData = verifyData;
    } else {
      this.$router.replace({
        path: "/error/expired",
      });
    }

    //Checking Browser Compatibility
    if (!DetectRTC.isWebRTCSupported || DetectRTC.isWebSocketsBlocked) {
      this.$router.replace({
        name: "incompatible",
      });
    }
  },
};
</script>
<style scoped>
.landingBackground {
  background: #fcfdff;
}
.vcipCard {
  margin-top: 1vh;
  color: black !important;
}
.vcipTitle {
  background: #eff4fc;
  color: #2f75c7;
}
</style>