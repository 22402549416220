import { end_points, base_url } from "@/config";
const axiosInstance = window.axiosInstance;

function checkStringValInObject(object){
    for(key in object){
      if(typeof(key) == 'string'){
        return object;
      }else{
        checkStringValInObject(object[key])
      }
    }
  };

  async function searchMatchedDropdownValues(typeId, searchTerm, val){
    const response = await axiosInstance.get(base_url + end_points.dropDownFields + typeId + "?value=" + searchTerm +"&perPage=100");
    let dropDownValues = [];
    dropDownValues = response.data.data;
    if(response.headers["totalpages"] && response.headers["totalpages"] > 1) {
        for(let i = 2; i<=response.headers["totalpages"]; i++) {
            if(searchTerm != val) {
                dropDownValues=[];
                break;
            }
            const values = await axiosInstance.get(base_url + end_points.dropDownFields + typeId + "?value=" + val + "&pageNumber=" + i +"&perPage=100");
            dropDownValues = dropDownValues.concat(values.data.data);
        }
    }
    
    return dropDownValues;
  }

  export {
    checkStringValInObject,
    searchMatchedDropdownValues
};