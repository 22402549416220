<template>
  <v-app>
    <v-navigation-drawer
      :mini-variant="mini"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            :key="item.text"
            active-class="activeMenu"
            :class="$route.path.includes(item.path) ? 'activeMenu' : ''"
            @click="$router.push({ path: item.path })"
          >
            <v-list-item-action>
              <v-icon
                :color="$route.path.includes(item.path) ? 'app_bar_icon' : ''"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="app_bar_color"
      dark
    >
      <v-app-bar-nav-icon @click.stop="toogleSidebar()" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <img height="30px;" :src="logoImageUrl" />
      </v-toolbar-title>
      <v-spacer />
      <!--logged in RE name-->
      <div style="display:contents;">
        <div>
          <img src="@/assets/loginUser.svg" style="vertical-align:middle;" />
        </div>
        <div style="margin-left:5px;">
          <div style="line-height:15px;font-size:18px;font-weight:bold;" :title='this.reManagerDetails.name'>
            {{this.reManagerDetails && this.reManagerDetails.name && this.reManagerDetails.name.length >= 15 ? this.reManagerDetails.name.substring(0,15) + '...' : this.reManagerDetails.name }}
          </div>
          <div style="line-height:15px;" :title='this.reManagerDetails.username'>
            {{this.reManagerDetails && this.reManagerDetails.username && this.reManagerDetails.username.length >= 20 ? this.reManagerDetails.username.substring(0,20) + '...' : this.reManagerDetails.username}}
          </div>
        </div>
      </div>
      <!--logged in RE name-->
      <v-btn depressed color="app_bar_color" @click="logoutDialog = true">
        Logout
        <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container>
        <v-card class="elevation-0">
          <v-card-title>
            My Team
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="reManagers" :search="search">
            <template v-slot:item.action="{ item }">
              <a @click="viewDetails(item)">View Details</a>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </v-content>
    <v-dialog v-model="showReDetails" persistent max-width="500">
      <v-card>
        <v-card-title class="title mb-3"></v-card-title>
        <v-card-text>
          <p class="title mb-1">{{ currentReDetails.name }}</p>
          <p>
            Username: {{ currentReDetails.username }}
            <br />
            Mobile: {{ currentReDetails.phone || "N/A" }}
            <br />
            Email: {{ currentReDetails.email || "N/A" }}
          </p>
          <div v-if="currentReDetails.callCounts">
            <v-divider style="width: 80% text-center"></v-divider>
            <p class="subtitle-1 mt-0 mb-0">Call logs</p>
            Sucessful calls:
            {{ currentReDetails.callCounts.totalSuccessCalls }}
            <br />
            Failed calls:
            {{ currentReDetails.callCounts.totalFailedCalls }}
            <br />
            Missed calls:
            {{ currentReDetails.callCounts.totalMissedCalls }}
            <br />
          </div>
          <div class="text-right">
            <v-btn color="primary darken-1" text @click="close()">Close</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog
        v-model="logoutDialog"
        persistent
        width="35%"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure, you want to logout?
          </v-card-title>
          <br>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logoutDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              color="error"
              @click="logout()"
            >
              Logout
              <v-icon class="ml-2">fas fa-sign-out-alt</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { sideBar, end_points } from "./../../config";
export default {
  methods: {
    toogleSidebar() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.drawer = true;
        this.mini = !this.mini;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },
    async viewDetails(re) {
      this.currentReDetails = {
        ...re,
      };
      try {
        let callData = (
          await axiosInstance.get(end_points.get_res_manager_count(re._id))
        ).data;
        this.currentReDetails["callCounts"] = callData;
      } catch (error) {
        console.log(error, re);
      }
      this.showReDetails = true;
    },
    close() {
      this.currentReDetails = {};
      this.showReDetails = false;
    },
    async logout() {
      axiosInstance.get(end_points.logout(sessionStorage.userId));
      eventBus.$emit("vueLoader", false);
      if (
        this.$store.getters.userData.customerData.config?.global?.redirectOnLogout
      ) {
        let urlToSend = this.$store.getters.userData.customerData.config.global.redirectOnLogoutURL;
        store.flush();
        window.open(urlToSend, "_self");
      } else {
        store.flush();
        this.$router.push({
          path: "/",
        });
      }
    },
    async getAllResOfManager(userId) {
      try {
        this.reManagers = (
          await axiosInstance.get(end_points.get_res_manager(userId))
        ).data;
      } catch (error) {
        console.log(error);
        this.reManagers = [];
      }
    },
    async getREManagerName(userId) {
      try {
        this.reManagerDetails = (
          await axiosInstance.get(end_points.get_res_manager_details(userId))
        ).data;
      } catch (error) {
        console.log(error);
      }
    },
    timerIncrement() {
      this.idleTime = this.idleTime + 1;
      if (this.idleTime > this.idleTimeout) {
        // 20 minutes
        window.clearInterval(this.idleInterval);
        axiosInstance.get(end_points.logout(sessionStorage.userId));
        eventBus.$emit("vueLoader", false);
        if (this.$store.getters.userData.customerData.config?.global?.redirectOnLogout) {
          let urlToSend = this.$store.getters.userData.customerData.config.global.redirectOnLogoutURL;
          store.flush();
          window.open(urlToSend, "_self");
        } else {
          store.flush();
          this.$router.push({
            path: "/",
          });
        }
      }
    }
  },
  created() {
    if (!this.$store.getters.userData) {
      this.$router.push("/");
    } else {
      this.userData = this.$store.getters.userData;
      this.getREManagerName(this.userData.userId);
      this.getAllResOfManager(this.userData.userId);
      const isIdleTimeNeeded =
        this.userData.customerData.config?.global?.idleTimeRequired || false;
      if (isIdleTimeNeeded) {
        window.clearInterval(this.idleInterval);
        this.idleTimeout = this.userData.customerData.config?.global?.idleTime || 10;
        this.idleInterval = setInterval(this.timerIncrement, 1000 * 60); // 1 minute
        let appHtml = document.getElementById("app");
        appHtml.addEventListener("mousemove", (e) => {
          this.idleTime = 0;
        });
        appHtml.addEventListener("keypress", (e) => {
          this.idleTime = 0;
        });
      }
    }
  },
  data: () => ({
    dialog: false,
    isVideoConference: false,
    drawer: null,
    search: "",
    showReDetails: false,
    currentReDetails: {},
    items: sideBar.teams,
    mini: false,
    userData: {},
    headers: [
      { text: "Username", value: "username" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Actions", value: "action", sortable: false },
    ],
    reManagers: [],
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    agentData: {},
    phone: "",
    idleTime: 0,
    idleTimeout: 10, //in mins
    idleInterval: {},
    adminCallTimeout: {},
    reManagerDetails: {},
    logoImageUrl: undefined,
    phoneRules: [
      (v) => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a proper mobile number!",
    ],
    askPhoneNumber: false,
    logoutDialog: false,
  }),
};
</script>

<style scoped>
.activeMenu {
  background: #d4d2d2;
  border-right: 4px solid #0184c4;
}
</style>