<template>
  <router-view :checkInterval="checkInterval"/>
</template>

<script>
export default {
  props: ['checkInterval']
};
</script>

<style>
</style>