<template>
  <v-content>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" sm="12" lg="8" class="pa-2 text-center">
          <span class="headline hidden-sm-and-down">The video conference is over</span>
          <span class="title hidden-md-and-up">The video conference is over</span>
          <v-card class="pa-2 mt-2">
            <v-card-text>
              <p class="title signzy_blue_text">Your feedback is valuable to us.</p>
              <p class="title">How would you rate your experience?</p>
              <v-rating
                color="yellow darken-3"
                background-color="grey darken-1"
                half-increments
                v-model="rating"
                hover
              ></v-rating>
              <p class="mt-5 subtitle-1">What would you like to improve?</p>
              <v-row justify="center">
                <v-col cols="10" md="10" sm="10" lg="10" xl="8" class="text-center">
                  <v-row>
                    <v-col cols="12" md="4" sm="4" lg="4" xl="4" class="pa-2 text-center">
                      <v-btn
                        small
                        block
                        dark
                        :outlined="!ux"
                        color="indigo"
                        @click="ux = !ux;"
                      >User experience</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" sm="4" lg="4" xl="4" class="pa-2 text-center">
                      <v-btn
                        small
                        block
                        dark
                        :outlined="!rescheduling"
                        color="indigo"
                        @click="rescheduling = !rescheduling;"
                      >User Rescheduling</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" sm="4" lg="4" xl="4" class="pa-2 text-center">
                      <v-btn
                        small
                        block
                        dark
                        :outlined="!something"
                        color="indigo"
                        @click="something = !something;"
                      >Something else</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" lg="12" xl="12" class="pa-0 mt-2 text-center">
                      <v-text-field
                        class="ma-0 pa-0"
                        label="Write your comments here"
                        v-model="comments"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" lg="12" xl="12" class="pa-0 text-center">
                      <v-btn dark color="primary" @click="submitFeedback()">Submit Feedback</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="12" lg="8" class="pa-2 mt-5 text-center">
          <div class="text-center">
            <v-btn outlined color="primary" @click="goToHome()">Return to Home Page</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { end_points } from "@/config";
export default {
  data: () => ({
    rating: 0,
    ux: false,
    rescheduling: false,
    something: false,
    comments: "",
    sessionId: ""
  }),
  methods: {
    async submitFeedback() {
      try {
        let res = await axiosInstance.post(
          end_points.update_admin_feedback(this.sessionId),
          {
            rating: this.rating,
            improvement: {
              ux: this.ux,
              rescheduling: this.rescheduling,
              something: this.something
            },
            comments: this.comments
          }
        );
        if (res.data) {
          this.goToHome();
          eventBus.$emit("vueSnack", "Successfully submitted!");
        } else {
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    },
    goToHome() {
      this.$router.replace({
        path: "/dashboard/conference"
      });
    }
  },
  created() {
    this.sessionId = this.$route.params.session;
  }
};
</script>

<style scoped>
.signzy_blue_text {
  color: #1365c0;
}
</style>