import { end_points } from "./../config";
const axiosInstance = window.axiosInstance;


const executeFaceMatch = async (firstImage, documents, customerId) => {
    /*facematchScore is the highest value of all the facematch scores from all valid documents for a particular instruction
    temp_facematch is for keeping track of indivial facematch scores of every considered documents */
    let facematchScore = 0.0, statuscheck=0, facematchDesc = [], temp_facematch = 0.0, faceMatchError = "";
    for (let i = 0; i < documents.length; i++) {
        temp_facematch = 0.0;
        try {
            eventBus.$emit("vueLoaderTextChange", "Running facematch, please wait...")
            //The firstImage will always be the screenshot and the secondImage will be the document
            const faceResp = await axiosInstance.post(end_points.face_match, {
                firstImage,
                secondImage: documents[i].documentImage,
                customerId
            });
            if(faceResp.data.status != 200){
                if(faceResp.data.message) {
                    faceMatchError = faceResp.data.message;
                }
                statuscheck =-1;
            }          
            if (faceResp.data.data.result) {
                // Onprem facematch deployments
                temp_facematch = parseFloat(faceResp.data.data.result.matchPercentage)
                if (parseFloat(faceResp.data.data.result.matchPercentage) > facematchScore) {
                    facematchScore = parseFloat(faceResp.data.data.result.matchPercentage)
                }
            } else if (faceResp.data.data) {
                temp_facematch = parseFloat(faceResp.data.data.matchPercentage)
                if (parseFloat(faceResp.data.data.matchPercentage) > facematchScore) {
                    facematchScore = parseFloat(faceResp.data.data.matchPercentage)
                }
            }
            facematchDesc.push({"documentType": documents[i].documentName , "facematchScore": temp_facematch })     
        } catch (error) {
            console.log(error);
            statuscheck =-1;
        }
    }
    return { Percent:facematchScore, facematchDesc:facematchDesc, status: statuscheck, faceMatchError: faceMatchError };
}
/*This needs to be done*/
const deleteImages = (imageArr) => {

}

/* This fuction is to consolidate the facematch value for different docs at different instructions 
reusable fn: reg type returns highest facematchScore of all the instructions 
             desc type returns facematch details of every instruction */
const getFaceMatchScoreFromInflow = (inflow, type="reg") => {
    let faceMatchScore = 0.0, facematchInflowDesc = [];
        inflow.forEach((instruction) => {
            if (instruction.facematchScore && instruction.facematchDesc ) {
                if(instruction.facematchScore > faceMatchScore){
                    faceMatchScore = instruction.facematchScore;
                }
                facematchInflowDesc.push({"instruction": instruction.question , "facematchScore" : [...instruction.facematchDesc]})
            }
        });
        if(type === "desc"){
            return facematchInflowDesc;
        }
    return faceMatchScore;
}

export {
    executeFaceMatch,
    deleteImages,
    getFaceMatchScoreFromInflow
}