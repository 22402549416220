<template>
  <v-row>
    <v-col
      lg="8"
      md="8"
      sm="0"
      class="align-content-end flex-wrap login-bg hidden-sm-and-down d-none d-md-flex d-lg-flex d-xl-flex"
    >
      <div class="lower-description">
        <p class="display-2">Welcome to Video Conference</p>
        <p class="headline">Update your password to proceed further</p>
      </div>
    </v-col>
    <v-col lg="4" md="4" sm="12" align="center">
      <div class="login-panel">
        <div class="login-form">
          <label for="password1">
            Enter new Password
            <Password
              label="Enter new Password"
              name="password1"
              :toggle="true"
              :badge="false"
              :showStrengthMeter=false
              v-model="password1"
              outlined
            ></Password>
            <div class="password-meter">
              <div class="password-strength-bar">
                  <span v-bind:class="{
                      'grey' : passwordStrength === 0,
                      'darkred' : passwordStrength === 1,
                      'red' : passwordStrength === 2,
                      'orange' : passwordStrength === 3,
                      'yellow' : passwordStrength === 4,
                      'green' : passwordStrength === 5
                  }"></span>
                  <span v-bind:class="{
                      'grey' : passwordStrength <=1,
                      'red' : passwordStrength === 2,
                      'orange' : passwordStrength === 3,
                      'yellow' : passwordStrength === 4,
                      'green' : passwordStrength === 5
                  }"></span>
                  <span v-bind:class="{
                      'grey' : passwordStrength <=2,
                      'orange' : passwordStrength === 3,
                      'yellow' : passwordStrength === 4,
                      'green' : passwordStrength === 5
                  }"></span>
                  <span v-bind:class="{
                      'grey' : passwordStrength <=3,
                      'yellow' : passwordStrength === 4,
                      'green' : passwordStrength === 5
                  }"></span>
                  <span v-bind:class="{
                      'grey' : passwordStrength <=4,
                      'green' : passwordStrength === 5
                  }"></span>
              </div>
            </div>

          </label>

          <p style="color: red" v-if="isLengthInvalid">Minimum of 8 characters required</p>
          <p style="color: red" v-if="isLowerCaseMissing">Lowercase characters are required</p>
          <p style="color: red" v-if="isUpperCaseMissing">Uppercase characters are required</p>
          <p style="color: red" v-if="isDigitMissing">Digits are required</p>
          <p style="color: red" v-if="isSymbolMissing">Symbols _$#@^!*+)({}'\?/&lt;&gt;.,^[] are required</p>

          <v-btn
            rounded
            color="primary"
            @click="updatePass"
            block
            :disabled="
              isLengthInvalid ||
              isLowerCaseMissing ||
              isUpperCaseMissing ||
              isDigitMissing ||
              isSymbolMissing"
            >Proceed</v-btn
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { end_points, vcip_end_points } from "@/config";
import Password from "vue-password-strength-meter";
import enLib from "crypto-js/aes";
let axiosInstance = window.axiosInstance;
let eventBus = window.eventBus;
//let cookieStore = new SignzyStorageService("cookie");
export default {
  data: () => ({
    password1: "",
    passwordStrength:0,
  }),
  components: { Password },
 computed: {
    isLengthInvalid() {
      return this.password1.length < 8;
    },
    isLowerCaseMissing() {
      return !/[a-z]/g.test(this.password1);
    },
    isUpperCaseMissing() {
      return !/[A-Z]/g.test(this.password1);
    },
    isDigitMissing() {
      return !/[0-9]/g.test(this.password1);
    },
    isSymbolMissing() {
      return !/[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(this.password1);
    },
  },  
methods: {
    async updatePass() {
      if(this.$route.params.adminRedirect || this.$route.query.adminRedirect ){
        let options = {
            method: "POST",
            url: end_points.setFirstTimePass(sessionStorage.getItem("userId")),
            data: {
              password: this.password1
            }
          };
        try {
          let resp = await axiosInstance(options);
          let data = resp.data;
          sessionStorage.clear();
          this.$router.replace("/admin/login");
        } catch (err) {
          console.log(err);
          eventBus.$emit("vueSnack", "Password reset token Expired!");
          this.$router.replace("/admin/login");
        }
      } else {
          let options = {
            method: "POST",
            url: end_points.setPass(sessionStorage.getItem("userId")),
            data: {
              password: this.password1
            }
          };
        try {
          let resp = await axiosInstance(options);
          let data = resp.data;
          sessionStorage.clear();
          this.$router.replace("/login");
        } catch (err) {
          console.log(err);
          eventBus.$emit("vueSnack", "Password reset token Expired!");
          this.$router.replace("/login");
        }
      }
    },
  calculatePasswordStrength() {
      this.passwordStrength = [
        this.isLengthInvalid,
        this.isLowerCaseMissing,
        this.isUpperCaseMissing,
        this.isDigitMissing,
        this.isSymbolMissing,
      ].filter(condition => !condition).length;
    },  
  },
  watch: {
    password1: 'calculatePasswordStrength',
  },
  mounted() {
    this.calculatePasswordStrength();
  },
  async created() {
    console.log(this.$route.params.token);
    console.log(this.$route.params.userId);
    console.log(sessionStorage.getItem("userId"));
    if (
      !sessionStorage.getItem("userId") &&
      !(this.$route.params.userId && this.$route.params.token)
    ) {
      this.$router.replace("/login");
    } else if (this.$route.params.userId && this.$route.params.token) {
      try {
        sessionStorage.setItem("userId", this.$route.params.userId);
        let [authToken, created, ttl] = atob(this.$route.params.token).split("|");
        let expiry = new Date((Date.parse(created) || 0) + +(ttl * 1000 || 0));

        if (+Date.now() > +expiry) {
          eventBus.$emit("vueSnack", "Password reset token Expired or invalid!");
          this.$router.replace("/login");
          return;
        }

        axiosInstance.defaults.headers.common["Authorization"] = authToken;
      } catch (err) {
        console.log("ERROR FOUND", err);
        this.$router.replace("/login");
      }
    }
  }
};
</script>


<style scoped>
.login-bg {
  background-color: #1891cf;
  background-size: cover;
  background-image: url("~@/assets/vcip_it_admin.svg"),
    linear-gradient(#313545, #242835);
}
.lower-description {
  position: absolute;
  margin-left: 20px;
  z-index: 1;
  color: white;
}
.login-panel {
  top: 30vh;
  position: relative;
}
.login-form {
  position: relative;
  width: 80%;
}
.signzy-logo {
  height: 80px;
}

  .password-meter {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .password-strength-bar {
      display: flex;
      width: 100%;
      height: 5px;
      overflow: hidden;
  }
  .password-strength-bar span {
      margin: 2px;
      flex-grow: 1;
      height: 100%;
  }
  .darkred{
    background-color: darkred;
  }
</style>