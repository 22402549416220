let errorMap = [
    {
        "code": "VKYC_E_040" ,
        "key" : "expired"
    },
    {
        "code": "VKYC_E_041" ,
        "key" : "nosupport"   
    },
    {
        "code": "VKYC_E_042" ,
        "key" : "noGrant"   
    },
    {
        "code": "VKYC_E_043" ,
        "key" : "abrupt"   
    },
    {
        "code": "VKYC_E_044" ,
        "key" : "pageNotFound"   
    },
    {
        "code": "VKYC_E_045" ,
        "key" : "notCompatible"   
    },
    {
        "code": "VKYC_E_046" ,
        "key" : "noStream"   
    },
    {
        "code": "VKYC_E_047" ,
        "key" : "heartbeatFailed"   
    },
    {
        "code": "VKYC_E_048" ,
        "key" : "documentExpired"   
    },
    {
        "code": "VKYC_E_049" ,
        "key" : "unexpectedError"   
    },
    {
        "code": "VKYC_E_050" ,
        "key" : "multipleSession"   
    },
    {
        "code": "VKYC_E_051" ,
        "key" : "ReDrop"   
    },
    {
        "code": "VKYC_E_052" ,
        "key" : "networkTooSlow"   
    },
    {
        "code": "VKYC_E_053" ,
        "key" : "browserVersionIncompatible"   
    },
   
    {
        "code": "VKYC_E_054" ,
        "key" : "browserIncompatible"   
    },
    {
        "code": "VKYC_E_055" ,
        "key" : "IOSBrowserIncompatible"   
    },
    {
        "code": "VKYC_E_056" ,
        "key" : "IOSBrowserVersionIncompatible"   
    },
   
    {
        "code": "VKYC_E_057" ,
        "key" : "mobileBrowserIncompatible"   
    },
    {
        "code": "VKYC_E_058" ,
        "key" : "mobileBrowserVersionIncompatible"   
    },
    {
        "code": "VKYC_E_059" ,
        "key" : "macBrowserIncompatible"   
    },
    {
        "code": "VKYC_E_060" ,
        "key" : "macBrowserVersionIncompatible"   
    },
];

// Returning VKYC_E_049 in case of any error here
// as it represents unexpected Error
const errorProcessor = (keyword) => {
    return new Promise((resolve, reject) => {
        if (keyword) {
            let errorCode = errorMap.filter(error => error.key === keyword);
            if (errorCode.length < 1) {
                reject("VKYC_E_049");
            } else {
                if (errorCode[0] && errorCode[0].code) {
                    resolve(errorCode[0].code); 
                } else {
                    reject("VKYC_E_049");
                }
            }
        } else {
            reject("VKYC_E_049");
        }
    })
}

export {
    errorProcessor
}